import React, { useCallback } from "react";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import { Engine } from "tsparticles-engine";

const HeroParticles = () => {
  const particlesInit = useCallback(async (engine: Engine) => {
    // this adds the bundle to tsParticles
    await loadFull(engine);
  }, []);

  return (
    <div>
      <Particles
        init={particlesInit}
        canvasClassName="particle-canvas"
        options={{
          fullScreen: {
            enable: true,
          },
          detectRetina: true,
          background: {
            color: "#000",
          },
          fpsLimit: 60,
          emitters: {
            direction: "top",
            life: {
              count: 0,
              duration: 0.08,
              delay: 0.1,
            },
            rate: {
              delay: 0.15,
              quantity: 1,
            },
            size: {
              width: 80,
              height: 0,
            },
            position: {
              y: 100,
              x: 50,
            },
          },
          particles: {
            color: { value: "#fff" },
            number: {
              density: { enable: true, area: 800 },
              value: 100,
              limit: 1000,
            },
            opacity: {
              random: false,
              value: 0.5,
            },
            life: {
              count: 0,
            },
            shape: {
              type: "line",
            },
            size: {
              value: { min: 1, max: 26 },
              animation: {
                enable: true,
                sync: true,
                speed: 0.8,
                startValue: "max",
                destroy: "min",
              },
            },
            stroke: {
              color: {
                value: "#ffffff",
              },
              width: 1,
            },
            rotate: {
              path: true,
              value: 180,
            },
            move: {
              straight: true,
              enable: true,
              speed: { min: 8, max: 15 },
              outModes: {
                default: "destroy",
                top: "none",
              },
              trail: {
                fillColor: "#000",
                enable: true,
                length: 3,
              },
            },
          },
        }}
      />
    </div>
  );
};

export default React.memo(HeroParticles);
