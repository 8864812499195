import React, { useEffect, useState } from "react";
import Button from "../../button";
import Container from "../../container";
import ArrowDown from "../../svg/arrow-down";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useContextUpdater } from "../../../context";
import { useForm, ValidationError } from "@formspree/react";
import { className } from "../../../utils/className";

const headingVariants = {
  hidden: {
    x: "200",
    opacity: 0,
  },
  visible: {
    x: 0,
    opacity: 1,
    transition: {
      duration: 1,
      ease: "easeInOut",
      delay: 0.5,
    },
  },
};

const formVariants = {
  hidden: {
    opacity: 0,
    scale: 0,
  },
  visible: {
    opacity: 1,
    scale: [0, 1, 0.8, 1],
    transition: {
      delay: 1,
      type: "spring",
      stiffness: 100,
      damping: 20,
      duration: 1.2,
    },
  },
};

const buttonVariants = {
  hidden: {
    opacity: 0,
    x: "200",
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 1,
      ease: "easeInOut",
      delay: 1.8,
      when: "beforeChildren",
    },
  },
};

export default function Contact() {
  const [states, setStates] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [formState, handleSubmit] = useForm(
    process.env.REACT_APP_FORMSPREE_KEY as string
  );
  const { ref, inView } = useInView({
    threshold: 0.5,
  });

  const { setActiveNav } = useContextUpdater();

  useEffect(() => {
    if (inView) {
      setActiveNav("contact");
    }
  }, [inView]);

  const onChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setStates((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  useEffect(() => {
    if (formState.succeeded) {
      setStates({
        name: "",
        email: "",
        message: "",
      });
    }
  }, [formState]);

  return (
    <Container
      heading="Contact"
      alternate
      titleClassName="text-white"
      underlineClassName="bg-white"
      className="pt-40 pb-10"
    >
      <div className="absolute top-0 w-full left-0">
        <ArrowDown />
      </div>
      <div className="text-center py-10" ref={ref}>
        <motion.p
          className="text-sm text-primary font-medium lg:text-base"
          variants={headingVariants}
          initial="hidden"
          whileInView="visible"
          viewport={{
            once: true,
          }}
        >
          Have a question or want to talk?
        </motion.p>
        <motion.form
          className="w-full space-y-2 flex flex-col max-w-lg py-10 px-4 mx-auto"
          variants={formVariants}
          initial="hidden"
          whileInView="visible"
          viewport={{
            once: true,
          }}
          onSubmit={handleSubmit}
        >
          <Input
            placeholder="Name"
            type="text"
            id="name"
            name="name"
            onChange={onChange}
            value={states.name}
          />
          <ValidationError
            prefix="name"
            field="name"
            errors={formState.errors}
          />
          <Input
            placeholder="Email"
            type="email"
            id="email"
            name="email"
            onChange={onChange}
            value={states.email}
          />
          <ValidationError
            prefix="email"
            field="email"
            errors={formState.errors}
          />
          <textarea
            placeholder="Message"
            className="p-2 bg-[#1e242c] transform focus:scale-105 transition-all duration-500 placeholder:text-white text-white font-medium focus:ring-white focus:ring-2 focus:rounded outline-0"
            rows={5}
            id="message"
            name="message"
            onChange={onChange}
            value={states.message}
          ></textarea>
          <ValidationError
            prefix="Message"
            field="message"
            errors={formState.errors}
          />
          {formState.succeeded && (
            <p className="text-primary font-medium text-xs sm:text-sm py-2 text-right">
              Thanks for contacting me i will get back to you as soon as
              possible
            </p>
          )}
          <motion.div
            className="flex justify-end "
            variants={buttonVariants}
            whileInView="visible"
            viewport={{
              once: true,
            }}
          >
            <Button
              className={className("text-white px-8 py-1 mt-2 uppercase", {
                "px-4 animate-pulse ": formState.submitting,
              })}
              isLoading={formState.submitting}
              type="submit"
              disabled={
                formState.submitting ||
                states.name === "" ||
                states.email === "" ||
                states.message === ""
              }
            >
              {formState.submitting ? "Sending..." : "Send"}
            </Button>
          </motion.div>
        </motion.form>
      </div>
    </Container>
  );
}

interface inputProps {
  type: string;
  placeholder: string;
  id: string;
  name: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value: string;
}

const Input = ({
  type = "text",
  placeholder,
  name,
  id,
  onChange,
  value,
}: inputProps) => {
  return (
    <input
      type={type}
      placeholder={placeholder}
      className="p-2 bg-[#1e242c] transform focus:scale-105 transition-all duration-500 placeholder:text-white focus:ring-white font-medium focus:ring-2 focus:rounded outline-0 text-white"
      id={id}
      name={name}
      onChange={onChange}
      value={value}
    />
  );
};
