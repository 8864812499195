import { useEffect, useState } from "react";
import { className } from "../../../utils/className";
import Container from "../../container";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useContextUpdater } from "../../../context";
import Card from "./Card";
import { Tag } from "../../../utils/enums";
import { flushSync } from "react-dom";

const menus = [
  {
    key: "all",
    label: "All",
  },
  {
    key: "react",
    label: "React",
  },
  {
    key: "go",
    label: "Go Lang",
  },
  {
    key: "js",
    label: "JS/TS",
  },
  {
    key: "node",
    label: "Node.js",
  },
  {
    key: "wordpress",
    label: "WordPress",
  },
];

const projects = [
  {
    key: "utap",
    bgUrl: "https://s3.ap-south-1.amazonaws.com/sharukh.dev/up-time-alert-pro/1.png",
    title: "Up Time Alert Pro",
    categories: ["go"],
    description: "",
    keyPoint: "",
    tech: ["Go Lang"],
    tag: Tag.personal,
  },
  {
    key: "estimat3",
    bgUrl: "https://s3.ap-south-1.amazonaws.com/sharukh.dev/estimat3/estimate-home.webp",
    title: "Estimat3",
    categories: ["js", "ts", "node", "react"],
    description: "",
    keyPoint: "",
    tech: ["Typescript", "Node.js", "React"],
    tag: Tag.personal,
  },
  {
    key: "mfm",
    bgUrl: "https://s3.ap-south-1.amazonaws.com/sharukh.dev/my-food-menu/1.png",
    title: "My Food Menu",
    categories: ["react"],
    description: "",
    keyPoint: "",
    tech: ["React"],
    tag: Tag.personal,
  },
  {
    key: "me",
    bgUrl: "https://s3.ap-south-1.amazonaws.com/sharukh.dev/map-engine/bg.webp",
    title: "Map Engine",
    categories: ["wordpress", "react"],
    description: "",
    keyPoint: "",
    tech: ["WordPress", "React"],
    tag: Tag.professional,
  },
  {
    key: "bee-dial",
    bgUrl: "https://s3.ap-south-1.amazonaws.com/sharukh.dev/bee-dial/1.png",
    title: "Bee Dial",
    categories: ["go"],
    description: "",
    keyPoint: "",
    tech: ["Go Lang"],
    tag: Tag.personal,
  },
  {
    key: "fv",
    bgUrl: "https://s3.ap-south-1.amazonaws.com/sharukh.dev/fv/fv-5.png",
    title: "Form Vibes",
    categories: ["wordpress", "react"],
    description: "",
    keyPoint: "20000+ Active Installs",
    tech: ["WordPress", "React"],
    tag: Tag.professional,
  },
  {
    key: "kfs",
    bgUrl:
      "https://s3.ap-south-1.amazonaws.com/sharukh.dev/kachuafuelstation/bg.png",
    title: "Kachua Fuel Station",
    categories: ["react", "firebase"],
    description: "",
    keyPoint: "",
    tech: ["React", "Firebase"],
    tag: Tag.professional,
  },
  {
    key: "trt",
    bgUrl: "https://s3.ap-south-1.amazonaws.com/sharukh.dev/trt/trt-bg.gif",
    title: "Tiny React Tooltip",
    categories: ["react"],
    description: "",
    keyPoint: "",
    tech: ["React"],
    tag: Tag.personal,
  },
  {
    key: "blr",
    bgUrl: "https://s3.ap-south-1.amazonaws.com/sharukh.dev/blr/bg.png",
    title: "Better Logs Remover",
    categories: ["js", "ts"],
    description: "",
    keyPoint: "",
    tech: ["Typescript"],
    tag: Tag.personal,
  },
];

export default function Projects() {
  const [activeTab, setActiveTab] = useState("all");
  const { ref, inView } = useInView({
    threshold: 0.5,
  });
  const [projectRef, projectInView] = useInView({
    threshold: 0.5,
  });
  const { setActiveNav } = useContextUpdater();
  const [filteredProjects, setFilteredProjects] = useState(projects);

  useEffect(() => {
    if (inView) {
      setActiveNav("portfolio");
    }
  }, [inView]);

  return (
    <>
      <Container heading="Projects" alternate={true}>
        <div className="mt-20">
          <motion.div
            className="relative flex flex-row flex-wrap items-center justify-center text-sm font-normal lg:text-base gap-y-2 "
            ref={ref}
          >
            {menus.map((menu, index) => {
              const { key, label } = menu;
              return (
                <motion.div
                  key={key}
                  className={className(
                    "px-4 py-2 cursor-pointer hover:bg-highlight hover:text-white transition-colors duration-500 dark:text-white",
                    {
                      "bg-highlight  text-white font-medium ":
                        key === activeTab,
                    }
                  )}
                  onClick={() => {
                    setActiveTab(key);
                    flushSync(() => {
                      setFilteredProjects([]);
                    });
                    setFilteredProjects(
                      key === "all"
                        ? projects
                        : projects.filter((p) => p.categories.includes(key))
                    );
                  }}
                  initial="hidden"
                  whileInView="visible"
                  viewport={{
                    once: true,
                  }}
                  variants={{
                    hidden: {
                      opacity: 0,
                    },
                    visible: {
                      opacity: 1,
                      transition: {
                        duration: 0.3,
                        delay: index * 0.5,
                      },
                    },
                  }}
                >
                  <h2>{label}</h2>
                </motion.div>
              );
            })}
          </motion.div>
          {filteredProjects.length ? (
            <div
              className="grid grid-cols-1 gap-4 mt-20 md:grid-cols-2 lg:grid-cols-3"
              ref={projectRef}
            >
              {filteredProjects.map((project, index) => {
                const { key, bgUrl, title, tech, keyPoint, tag } = project;
                return (
                  <Card
                    projectKey={key}
                    bgUrl={bgUrl}
                    title={title}
                    tech={tech}
                    keyPoint={keyPoint}
                    tag={tag}
                    index={index}
                    projectInView={projectInView}
                    key={key}
                  />
                );
              })}
            </div>
          ) : (
            <div className="mt-8 text-center">
              <h2 className="text-base font-medium dark:text-gray-300">
                I am working on it...
              </h2>
            </div>
          )}
        </div>
      </Container>
    </>
  );
}
