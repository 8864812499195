import { useStateContext } from "../../context";

export default function ArrowDown() {
  const { theme } = useStateContext();

  return (
    <svg
      preserveAspectRatio="none"
      viewBox="0 0 100 102"
      height="75"
      width="100%"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      // className="absolute top-0"
    >
      <path
        d="M0 0 L50 100 L100 0 Z"
        fill={theme ? "white" : "#222831"}
        stroke={theme ? "white" : "#222831"}
      ></path>
    </svg>
  );
}
