import { motion, AnimatePresence } from "framer-motion";

const backdrop = {
  visible: { opacity: 1 },
  hidden: { opacity: 0 },
};

const modal = {
  hidden: {
    opacity: 0,
    scale: 0,
  },
  visible: {
    opacity: 1,
    scale: [0, 1, 0.8, 1],
    transition: { delay: 0.5 },
  },
};

interface Props {
  showModal: boolean;
  onOutsideClick: () => void;
  children: React.ReactNode;
}

const Modal = ({ showModal, onOutsideClick, children }: Props) => {
  return (
    <AnimatePresence>
      {showModal && (
        <motion.div
          className=" fixed top-0 left-0 lg:flex items-center w-full min-h-full bg-[#00000080] z-20"
          variants={backdrop}
          initial="hidden"
          animate="visible"
          exit="hidden"
          onClick={onOutsideClick}
        >
          <motion.div
            className="modal"
            variants={modal}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {children}
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Modal;
