export const transition = {
  type: "spring",
  stiffness: 200,
  damping: 10,
};

export const whileTap = {
  scale: 0.95,
  rotate: 15,
};
