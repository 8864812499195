import { motion } from "framer-motion";
import React, { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { useContextUpdater } from "../../../context";
import Button from "../../button";
import ArrowRight from "../../svg/arrow-right";
import FileCodeSvg from "../../svg/file-code";
import HeroParticles from "./HeroParticles";

const Hero = () => {
  const { ref, inView } = useInView({
    threshold: 0.5,
  });
  const { setActiveNav } = useContextUpdater();

  useEffect(() => {
    if (inView) {
      setActiveNav("home");
    }
  }, [inView]);

  return (
    <>
      <div
        className=" w-full h-screen bg-gray-800 text-white relative"
        ref={ref}
      >
        <HeroParticles />
        <motion.div
          className=" absolute top-2/4 left-2/4 transform -translate-x-2/4 -translate-y-2/4 w-full text-center"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 2, delay: 3 }}
        >
          <motion.h1 className="text-3xl sm:text-4xl md:text-5xl">
            Hello, I&apos;m{" "}
            <motion.div
              className="inline relative  font-semibold text-transparent "
              transition={{ delay: 4 }}
            >
              {"Sharukh Khan".split("").map((letter, index) => {
                if (letter === " ") {
                  return " ";
                }
                return (
                  <motion.span
                    className="transparent-text inline-block"
                    key={index}
                    initial={{
                      // opacity: 0,
                      textShadow: "0px 0px 0px rgba(255, 255, 255, 0.5)",
                    }}
                    animate={{
                      opacity: [0, 1],
                      textShadow: [
                        "0px 0px 100px rgba(255, 255, 255, 0.5)",
                        "0px 0px 50px rgba(255, 255, 255, 0.5)",
                        "0px 0px 10px rgba(255, 255, 255, 0.5)",
                      ],
                    }}
                    transition={{
                      duration: 2,
                      ease: "easeOut",
                      delay: letter === " " ? -1 : index * 0.4,
                      repeat: Infinity,
                      repeatType: "mirror",
                    }}
                  >
                    {letter}
                  </motion.span>
                );
              })}
              <div className="inline absolute top-0 w-full left-0 text-highlight font-semibold">
                {"Sharukh Khan".split("").map((letter, index) => {
                  if (letter === " ") {
                    return " ";
                  }
                  return (
                    <motion.span
                      className=" inline-block"
                      key={index}
                      initial={{
                        opacity: 0,
                      }}
                      animate={{
                        opacity: 1,
                      }}
                      transition={{
                        duration: 0.5,
                        ease: "easeInOut",
                        delay: letter === " " ? -1 : index * 0.2,
                      }}
                    >
                      {letter}
                    </motion.span>
                  );
                })}
              </div>
            </motion.div>
          </motion.h1>
          <motion.h2 className="text-xl sm:text-2xl md:text-3xl">
            I&apos;m a full-stack web developer.
          </motion.h2>
          <div className="flex items-center justify-center gap-x-4 mt-4 flex-row-reverse">
            <a className="flex justify-center items-center " href="#about">
              <Button>
                <span className="sm:text-lg md:text-xl">View my work</span>{" "}
                <ArrowRight />
              </Button>
            </a>
            <a href="/sharukh-resume.pdf" download>
              <Button className="bg-primary border-primary">
                <span className="sm:text-lg md:text-xl">Resume</span>{" "}
                <FileCodeSvg />
              </Button>
            </a>
          </div>
        </motion.div>
      </div>
    </>
  );
};

export default React.memo(Hero);
