import Container from "../../container";
import Github from "../../svg/github";
import Heart from "../../svg/heart";
import ReactSvg from "../../svg/react";
import UpSvg from "../../svg/up";
import { motion } from "framer-motion";
import LinkedIn from "../../svg/linkedin";
import Twitter from "../../svg/twitter";
import Instagram from "../../svg/instagram";
import { useInView } from "react-intersection-observer";

const socialItems = [
  {
    icon: Github,
    name: "GitHub",
    link: "https://github.com/sharukhkhanajm",
  },
  {
    icon: LinkedIn,
    name: "LinkedIn",
    link: "https://www.linkedin.com/in/sharukhkhanajm",
  },
  {
    icon: Twitter,
    name: "Twitter",
    link: "https://twitter.com/iamsharukh_khan",
  },
  {
    icon: Instagram,
    name: "Instagram",
    link: "https://www.instagram.com/iamsharukhkhan_/",
  },
];

export default function Footer() {
  const { ref } = useInView({
    threshold: 0.5,
  });
  return (
    <Container className="relative">
      <div className="absolute -top-6 right-1/2 transform translate-x-2/4 translate-y-0">
        <a href="#home">
          <motion.button
            className=" bg-highlight p-3  flex cursor-pointer "
            initial="hidden"
            whileInView="visible"
            viewport={{
              once: true,
            }}
            variants={{
              hidden: {
                y: "100%",
                opacity: 0,
              },
              visible: {
                y: 0,
                opacity: 1,
                transition: {
                  duration: 1,
                  delay: 2,
                  type: "spring",
                  stiffness: 100,
                  damping: 15,
                },
              },
            }}
          >
            <UpSvg className="text-2xl text-white" />
          </motion.button>
        </a>
      </div>
      <div className="flex justify-between max-w-xs mx-auto" ref={ref}>
        {socialItems.map((item, index) => {
          const { icon: Icon, name, link } = item;
          return (
            <motion.a
              key={name}
              className="bg-[#262f38] p-3 hover:shadow-primary shadow transition-all duration-500 hover:scale-150 transform"
              href={link || "/"}
              target="_blank"
              rel="noreferrer"
              whileHover={{ scale: 1.1 }}
              initial="hidden"
              whileInView="visible"
              viewport={{
                once: true,
              }}
              variants={{
                hidden: {
                  rotateY: 90,
                  opacity: 0,
                  y: 0,
                },
                visible: {
                  rotateY: 0,
                  opacity: 1,
                  transition: { duration: 0.5, delay: index * 0.3 },
                },
              }}
            >
              <Icon className="text-white text-3xl" />
            </motion.a>
          );
        })}
      </div>
      <motion.div
        className="flex justify-center text-sm text-highlight mt-9 "
        initial="hidden"
        whileInView="visible"
        viewport={{
          once: true,
        }}
        variants={{
          hidden: {
            x: -100,
            opacity: 0,
          },
          visible: {
            x: 0,
            opacity: 1,
            transition: {
              duration: 1,
              delay: 1,
              type: "spring",
              stiffness: 100,
              damping: 15,
            },
          },
        }}
      >
        <span className="uppercase">sharukh khan</span>
        <span className="ml-1 font-semibold text-primary">
          &copy; {new Date().getFullYear()}
        </span>
      </motion.div>
      <motion.div
        className="flex items-center justify-center text-sm text-white lowercase mt-1"
        whileInView="visible"
        initial="hidden"
        viewport={{
          once: true,
        }}
        variants={{
          hidden: {
            x: 100,
            opacity: 0,
          },
          visible: {
            x: 0,
            opacity: 1,
            transition: {
              duration: 1,
              delay: 1.5,
              type: "spring",
              stiffness: 100,
              damping: 15,
            },
          },
        }}
      >
        made with
        <Heart className="inline w-5 h-5 ml-1 animate-pulse text-highlight" />
        &amp;
        <ReactSvg className="inline w-5 h-5 mr-1 animate-spin text-primary" />{" "}
      </motion.div>
    </Container>
  );
}
