import BoxArrow from "../../svg/box-arrow";
import X from "../../svg/x";

import { Carousel } from "../../Carousel";
import Button from "../../button";
import { motion } from "framer-motion";

interface ModalContentProps {
  onClose: () => void;
  images: string[];
  title: string;
  subTitle: string;
  description: string;
  link1: string;
  link2?: string;
  isWp: boolean;
  link1Text?: string;
  link2Text?: string;
  imgClassNames?: string;
}

export function ModalContent({
  onClose,
  images,
  description,
  isWp,
  link1,
  subTitle,
  title,
  link2,
  link1Text,
  link2Text,
  imgClassNames="h-full w-full",
}: ModalContentProps) {
  return (
    <div className="relative w-full h-full ">
      <div className="h-[250px] md:h-[450px] w-full">
        {/* <img src={mapEngineImage1} className="object-fill w-full h-full" /> */}
        <Carousel interval={3000} autoPlay={false}>
          {images.map((img, index) => (
            <img
              draggable="false"
              src={img}
              key={index}
              className={`object-fill ${imgClassNames}`}
              alt={title}
              loading="lazy"
            />
          ))}
        </Carousel>
      </div>
      <div className="px-6 py-4">
        <h2 className="text-lg font-bold capitalize md:text-xl lg:text-2xl">
          {title}
        </h2>
        <h5 className="text-sm font-semibold text-gray-400 md:text-base lg:text-xl">
          {subTitle}
        </h5>
        <hr className="my-2" />
        <p className="my-4 text-xs text-gray-500 md:text-sm lg:text-base">
          {description}
        </p>
        <div className="flex justify-between">
          <div className="flex flex-col items-start space-x-0 space-y-2 sm:flex-row sm:items-center sm:space-x-4 sm:space-y-0">
            {link1 ? (
              <a href={link1} target="_blank" rel="noreferrer">
                <Button className="flex items-center px-5 font-medium text-white uppercase bg-highlight border-highlight hover:bg-highlight hover:border-highlight">
                  <BoxArrow className="w-3.5 h-3.5" />
                  <span>{link1Text || "View Site"}</span>
                </Button>
              </a>
            ) : null}
            {isWp && link2 ? (
              <a href={link2} target="_blank" rel="noreferrer">
                <Button className="flex items-center px-5 font-medium text-white uppercase bg-primary border-primary ">
                  <BoxArrow className="w-3.5 h-3.5" />
                  <span>{link2Text || "View Product"}</span>
                </Button>
              </a>
            ) : null}
          </div>
          <motion.button
            onClick={onClose}
            initial={{
              rotate: 0,
            }}
            whileHover={{
              rotate: 360,
              transition: {
                duration: 0.5,
              },
            }}
          >
            <X className="text-gray-500" />
          </motion.button>
        </div>
      </div>
    </div>
  );
}
