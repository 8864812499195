import {
  animate,
  AnimationOptions,
  PanInfo,
  useMotionValue,
} from "framer-motion";

import Arrow from "./Arrow";
import Slider from "./Slider";
import Dots from "./Dots";
import { CarouselProps } from "../../utils/types";
import ChevronRight from "../svg/chevron-right";
import React from "react";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const transition: AnimationOptions<any> = {
  type: "spring",
  bounce: 0,
};

// eslint-disable-next-line react/display-name
const Container = React.forwardRef<
  HTMLDivElement,
  { children: React.ReactNode }
>((props, ref) => (
  <div ref={ref} className="relative w-full h-full overflow-x-hidden flex">
    {props.children}
  </div>
));

export const Carousel = ({
  children,
  renderArrowLeft,
  renderArrowRight,
  renderDots,
  autoPlay = true,
  interval = 2000,
  loop = true,
}: CarouselProps) => {
  const x = useMotionValue(0);
  const containerRef = React.useRef<HTMLDivElement>(null);
  const [index, setIndex] = React.useState(0);

  const calculateNewX = () => -index * (containerRef.current?.clientWidth || 0);

  const handleEndDrag = (e: Event, dragProps: PanInfo) => {
    const clientWidth = containerRef.current?.clientWidth || 0;

    const { offset } = dragProps;

    if (offset.x > clientWidth / 4) {
      handlePrev();
    } else if (offset.x < -clientWidth / 4) {
      handleNext();
    } else {
      animate(x, calculateNewX(), transition);
    }
  };

  const childrens = React.Children.toArray(children);

  const handleNext = () => {
    const idx = loop ? 0 : index;
    setIndex(index + 1 === childrens.length ? idx : index + 1);
  };

  const handlePrev = () => {
    const idx = loop ? childrens.length - 1 : 0;
    setIndex(index - 1 < 0 ? idx : index - 1);
  };

  React.useEffect(() => {
    const controls = animate(x, calculateNewX(), transition);
    return controls.stop;
  }, [index]);

  React.useEffect(() => {
    if (!autoPlay) {
      return;
    }
    const timer = setInterval(() => handleNext(), interval);
    return () => clearInterval(timer);
  }, [handleNext, interval]);

  return (
    <Container ref={containerRef}>
      {childrens.map((child, i) => (
        <Slider onDragEnd={handleEndDrag} x={x} i={i} key={i}>
          {child}
        </Slider>
      ))}
      {/* left arrow */}
      {renderArrowLeft ? (
        renderArrowLeft({ handlePrev, activeIndex: index })
      ) : (
        <Arrow left onClick={handlePrev}>
          <ChevronRight className="transform rotate-180" />
        </Arrow>
      )}

      {/* right arrow */}
      {renderArrowRight ? (
        renderArrowRight({ handleNext, activeIndex: index })
      ) : (
        <Arrow onClick={handleNext}>
          <ChevronRight className="stroke" />
        </Arrow>
      )}

      {/* dots */}
      {renderDots ? (
        renderDots({ setActiveIndex: setIndex, activeIndex: index })
      ) : (
        <Dots
          length={childrens.length}
          setActiveIndex={setIndex}
          activeIndex={index}
        />
      )}
    </Container>
  );
};
