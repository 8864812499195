import { motion } from "framer-motion";
import { useStateContext } from "../../../context";
import { className } from "../../../utils/className";
import Container from "../../container";
import DarkModeSwitcher from "../../DarkModeSwitcher";

const menu = ["home", "about", "portfolio", "blog", "contact"];

export default function Desktop() {
  return (
    <div className="bg-gray-800 ">
      <Container needHeading={false} className="py-0">
        <div className="flex items-center justify-between">
          <div className=" h-16 flex justify-start items-center  text-white text-lg uppercase space-x-8">
            {menu.map((item, index) => {
              return <Item key={index} index={index} item={item} />;
            })}
          </div>

          <DarkModeSwitcher />
        </div>
      </Container>
      <motion.div
        className="w-full h-1 bg-primary"
        initial={{
          width: 0,
        }}
        animate={{
          width: "100%",
        }}
        transition={{
          duration: 1,
        }}
      ></motion.div>
    </div>
  );
}

interface ItemProps {
  item: string;
  index: number;
}

const Item = ({ item, index }: ItemProps) => {
  const { activeNav } = useStateContext();

  return (
    <motion.a
      className={className(
        "hover:text-highlight transform transition-all duration-500",
        {
          "text-highlight": activeNav === item,
        }
      )}
      href={`#${item}`}
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity: 1,
      }}
      transition={{
        duration: 1,
        delay: index * 0.2,
      }}
    >
      {item}
    </motion.a>
  );
};
