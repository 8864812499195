import { useEffect, useState } from "react";
import { useContextUpdater, useStateContext } from "../../context";
import { MoonIcon } from "./MoonIcon";
import { SunIcon } from "./SunIcon";

export default function DarkModeSwitcher() {
  const { setTheme } = useContextUpdater();
  const { theme } = useStateContext();

  useEffect(() => {
    if (!theme) {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
    localStorage.setItem("theme", theme.toString());
  }, [theme]);

  return theme ? (
    <button onClick={() => setTheme(0)}>
      <SunIcon />
    </button>
  ) : (
    <button onClick={() => setTheme(1)}>
      <MoonIcon />
    </button>
  );
}
