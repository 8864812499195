import { className } from "../../utils/className";
import LoadingSvg from "../svg/loading";

type Props = {
  className?: string;
  children: React.ReactNode;
  onClick?: () => void;
  isLoading?: boolean;
  disabled?: boolean;
  type?: "button" | "submit" | "reset";
};

const Button = ({
  className: classNameProp = "",
  children,
  onClick,
  isLoading = false,
  disabled = false,
  type = "button",
}: Props) => {
  return (
    <button
      onClick={onClick}
      className={className(
        "flex justify-center items-center transform hover:scale-110 border-2 px-4 py-1.5 space-x-2 group hover:bg-primary hover:border-primary transition-all duration-300 cursor-pointer",
        {
          "cursor-not-allowed": disabled || isLoading,
        },
        classNameProp
      )}
      disabled={isLoading || disabled}
      type={type}
    >
      {children}
      {isLoading && <LoadingSvg className="ml-4" />}
    </button>
  );
};

export default Button;
