import { className } from "../../utils/className";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

type Props = {
  children: React.ReactNode;
  needHeading?: boolean;
  className?: string;
  heading?: string;
  alternate?: boolean;
  titleClassName?: string;
  underlineClassName?: string;
};

export default function Container({
  children,
  className: classNameProp = "",
  heading = "",
  alternate = false,
  titleClassName = "",
  underlineClassName = "",
}: Props) {
  const { ref } = useInView({
    threshold: 0.5,
  });

  return (
    <div
      className={className(
        "max-w-7xl mx-auto sm:px-6 lg:px-8 py-24 ",
        classNameProp
      )}
    >
      {heading && (
        <div ref={ref}>
          <motion.h2
            className={className(
              "text-center font-bold text-4xl uppercase text-gray-800 dark:text-gray-50",
              titleClassName
            )}
            transition={{
              duration: 1,
            }}
            initial="hidden"
            whileInView="visible"
            viewport={{
              once: true,
            }}
            variants={{
              hidden: {
                x: alternate ? "70vw" : "-70vw",
                opacity: 0,
              },
              visible: {
                x: 0,
                opacity: [0, 0.3, 0.5, 0.7, 1],
              },
            }}
          >
            {heading}
          </motion.h2>
          <motion.div
            className={className(
              "h-1 bg-gray-800 w-16 mx-auto my-5 dark:bg-gray-50",
              underlineClassName
            )}
            transition={{
              duration: 1,
              delay: 1,
              type: "spring",
              stiffness: 50,
            }}
            initial="hidden"
            whileInView="visible"
            viewport={{
              once: true,
            }}
            variants={{
              hidden: {
                x: alternate ? "50vw" : "-50vw",
                opacity: 0,
              },
              visible: {
                x: 0,
                opacity: [0, 0.5, 1],
              },
            }}
          ></motion.div>
        </div>
      )}

      {children}
    </div>
  );
}
