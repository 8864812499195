import { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import MenuSvg from "../../svg/menu";
import { className } from "../../../utils/className";
import { useStateContext } from "../../../context";
import DarkModeSwitcher from "../../DarkModeSwitcher";

const menu = ["home", "about", "portfolio", "blog", "contact"];

export default function Mobile() {
  const [open, setOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const { activeNav } = useStateContext();

  useEffect(() => {
    if (ref.current) {
      if (open) {
        ref.current.style.maxHeight = `${ref.current.scrollHeight}px`;
      } else {
        ref.current.style.maxHeight = "0";
      }
    }
  }, [open]);

  return (
    <div className="">
      <div className="bg-gray-800 h-14 flex justify-end items-center px-1 space-x-4">
        <DarkModeSwitcher />
        <button onClick={() => setOpen((prev) => !prev)}>
          <MenuSvg />
        </button>
      </div>
      <motion.div
        className="w-full h-1 bg-primary"
        initial={{
          width: 0,
        }}
        animate={{
          width: "100%",
        }}
        transition={{
          duration: 1,
        }}
      ></motion.div>
      <div
        className="px-4 overflow-hidden max-h-0 transition-all duration-700 bg-[#333]"
        ref={ref}
      >
        <ul
          className={className("space-y-2 py-4 uppercase text-lg text-white")}
        >
          {menu.map((item, index) => {
            return (
              <li key={index}>
                <a
                  className={className({
                    "text-highlight": activeNav === item,
                  })}
                  href={`#${item}`}
                  onClick={() => setOpen(false)}
                >
                  {item}
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}
