import { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { useContextUpdater } from "../../../context";
import Container from "../../container";

export default function Blog() {
  const { ref, inView } = useInView({
    threshold: 0.5,
  });
  const { setActiveNav } = useContextUpdater();

  useEffect(() => {
    if (inView) {
      setActiveNav("blog");
    }
  }, [inView]);

  return (
    <Container heading="Blog">
      <p className="text-center py-10 dark:text-white" ref={ref}>
        Coming soon...
      </p>
    </Container>
  );
}
