import { className } from "../../utils/className";
import { DotProps } from "../../utils/types";

const Dots = ({ length, activeIndex, setActiveIndex }: DotProps) => {
  return (
    <div className="absolute bottom-[10px] left-2/4 transform -translate-x-1/2">
      {new Array(length).fill("").map((_, i) => (
        <span
          onClick={() => setActiveIndex(i)}
          key={i}
          className={className(
            "w-2.5 h-2.5 rounded-full mx-2.5 inline-block bg-gray-300 transform hover:scale-125",
            {
              "scale-125 bg-gray-900": i === activeIndex,
            }
          )}
        ></span>
      ))}
    </div>
  );
};

export default Dots;
