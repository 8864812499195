import { useEffect, useState } from "react";
import Modal from "../../Modal";
import { AnimatePresence, motion } from "framer-motion";
import { Tag } from "../../../utils/enums";
import Button from "../../button";
import { ModalContent } from "./ModalContent";

interface CardProps {
  projectKey: string;
  bgUrl: string;
  title: string;
  tech: string[];
  keyPoint: string;
  tag: Tag;
  projectInView: boolean;
  index: number;
}

export default function Card({
  projectKey,
  bgUrl,
  title,
  tech,
  keyPoint,
  tag,
  index,
}: CardProps) {
  const [activeProject, setActiveProject] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [modalStates, setModalStates] = useState<{
    title: string;
    subTitle: string;
    description: string;
    link1: string;
    link2: string;
    isWp: boolean;
    images: string[];
    link1Text?: string;
    link2Text?: string;
    imgClassNames?: string;
  }>({
    title: "",
    subTitle: "",
    description: "",
    link1: "",
    link2: "",
    isWp: true,
    images: [],
    link1Text: "",
    link2Text: "",
    imgClassNames: "",
  });

  useEffect(() => {
    const onEscape = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        setShowModal(false);
      }
    };

    window.addEventListener("keydown", onEscape);

    return () => window.removeEventListener("keydown", onEscape);
  }, []);

  useEffect(() => {
    if (showModal) {
      setActiveProject("");

      switch (projectKey) {
        case "me":
          setModalStates({
            title: "Map Engine",
            subTitle: "An Ultimate Map Solution for your WordPress Websites",
            description:
              "MapEngine can be your one-stop destination to create fully customized Google Maps or OpenStreet Maps.There are no restrictions on the map platform selected. switch seamlessly between Google Map and OpenStreet Map. Using this plugin, you can add an unlimited number of markers and shapes to your map. An extremely user-friendly interface to ease up your map building experience without any hassle!",
            isWp: true,
            link1: "https://wpmapengine.com/",
            link2: "https://wordpress.org/plugins/map-engine/",
            images: [
              "https://s3.ap-south-1.amazonaws.com/sharukh.dev/map-engine/custom+marker+icons.gif",
              "https://s3.ap-south-1.amazonaws.com/sharukh.dev/map-engine/Info+Window.gif",
              "https://s3.ap-south-1.amazonaws.com/sharukh.dev/map-engine/marker+shapes.gif",
              "https://s3.ap-south-1.amazonaws.com/sharukh.dev/map-engine/marker+style.gif",
              "https://s3.ap-south-1.amazonaws.com/sharukh.dev/map-engine/polygon.gif",
            ],
          });
          break;
        case "fv":
          setModalStates({
            title: "Form Vibes",
            subTitle: "The ultimate tool to manage your Form Submissions",
            description:
              "Form Vibes lets you save the form submissions from various form plugins in the database. It also provides a graphical analytics report that allows you to visualize how different forms are performing.",
            isWp: true,
            link1: "https://formvibes.com/",
            link2: "https://wordpress.org/plugins/form-vibes/",
            images: [
              "https://s3.ap-south-1.amazonaws.com/sharukh.dev/fv/fv-1.gif",
              "https://s3.ap-south-1.amazonaws.com/sharukh.dev/fv/fv-2.gif",
              "https://s3.ap-south-1.amazonaws.com/sharukh.dev/fv/fv-3.gif",
              "https://s3.ap-south-1.amazonaws.com/sharukh.dev/fv/fv-4.gif",
            ],
          });
          break;
        case "trt":
          setModalStates({
            title: "Tiny React Tooltip",
            subTitle: "A simple and easy to use React tooltip.",
            description:
              "Tiny React Tooltip is a React component that lets you add tooltip on any other label or component in your React application.",
            isWp: false,
            link1: "https://www.npmjs.com/package/tiny-react-tooltip",
            link2: "https://www.npmjs.com/package/tiny-react-tooltip",
            images: [
              "https://s3.ap-south-1.amazonaws.com/sharukh.dev/trt/trt-bg.gif",
              "https://s3.ap-south-1.amazonaws.com/sharukh.dev/trt/trt-1.png",
              "https://s3.ap-south-1.amazonaws.com/sharukh.dev/trt/trt-2.png",
              "https://s3.ap-south-1.amazonaws.com/sharukh.dev/trt/trt-3.png",
              "https://s3.ap-south-1.amazonaws.com/sharukh.dev/trt/trt-4.png",
            ],
            link1Text: "View package",
          });
          break;
        case "kfs":
          setModalStates({
            title: "Kachua Fuel Station",
            subTitle: "A private fuel station management",
            description:
              "kachuafuelstation.com is a website that manages fuel station transactions, reports, credits etc. Its a complete management tool for a fuel station in India.",
            isWp: false,
            link1: "",
            link2: "",
            images: [
              "https://s3.ap-south-1.amazonaws.com/sharukh.dev/kachuafuelstation/1.gif",
              "https://s3.ap-south-1.amazonaws.com/sharukh.dev/kachuafuelstation/2.gif",
              "https://s3.ap-south-1.amazonaws.com/sharukh.dev/kachuafuelstation/3.gif",
            ],
          });
          break;
        case "blr":
          setModalStates({
            title: "Better Logs Remover",
            subTitle: "A VsCode extension for removing the logs from the file",
            description:
              "Better Logs Remover is a VsCode extension that helps in order to remove all the PHP and JavaScript logs statements from the current file in single command.",
            isWp: false,
            link1:
              "https://marketplace.visualstudio.com/items?itemName=SharukhKhan.better-logs-remover",
            link2:
              "https://marketplace.visualstudio.com/items?itemName=SharukhKhan.better-logs-remover",
            images: [
              "https://s3.ap-south-1.amazonaws.com/sharukh.dev/blr/blr-2.gif",
              "https://s3.ap-south-1.amazonaws.com/sharukh.dev/blr/blr-3.gif",
            ],
          });
          break;
          case "estimat3":
            setModalStates({
              title: "Estimat3",
              subTitle: "Quote With Confidence. Win With Margins.",
              description:
                "Estimat3 is an advanced estimation and quoting web application tailored for construction companies. It enables users to manage materials, task templates, crews, and project bids with integrated calculations. The visually appealing and user-friendly interface makes it suitable for general companies as well.",
              isWp: false,
              link1:
                "https://estimat3.com/",
              link2:
                "https://estimat3.com/",
              images: [
                "https://s3.ap-south-1.amazonaws.com/sharukh.dev/estimat3/estimate-home.webp",
                "https://s3.ap-south-1.amazonaws.com/sharukh.dev/estimat3/integration-2.webp",
                "https://s3.ap-south-1.amazonaws.com/sharukh.dev/estimat3/integration-2a.webp"
              ],
            });
          break;
          case "bee-dial":
            setModalStates({
              title: "Estimat3",
              subTitle: "Bee Grafting Management System",
              description:
                "Bee Dial is a web application designed for farmers to efficiently register and manage bee grafting activities using virtual mapping tools. It streamlines the process of bee management, offering a user-friendly interface for visualizing and planning grafting tasks.",
              isWp: false,
              link1:
                "",
              link2:
                "",
              images: [
                "https://s3.ap-south-1.amazonaws.com/sharukh.dev/bee-dial/1.png",
                "https://s3.ap-south-1.amazonaws.com/sharukh.dev/bee-dial/2.png",
                "https://s3.ap-south-1.amazonaws.com/sharukh.dev/bee-dial/3.png",
                "https://s3.ap-south-1.amazonaws.com/sharukh.dev/bee-dial/4.png"
              ],
            });
            break;
          case "utap":
            setModalStates({
              title: "Up Time Alert Pro",
              subTitle: "Monitor your website's uptime with ease",
              description:
                "Up Time Alert Pro is a robust website uptime monitoring tool with real-time monitoring, proactive email alerts for downtime, intuitive incident tracking, and multi-region performance analysis.",
              isWp: false,
              link1:
                "https://uptimealertpro.com",
              link2:
                "https://uptimealertpro.com",
              images: [
                "https://s3.ap-south-1.amazonaws.com/sharukh.dev/up-time-alert-pro/1.png",
                "https://s3.ap-south-1.amazonaws.com/sharukh.dev/up-time-alert-pro/2.png",
                "https://s3.ap-south-1.amazonaws.com/sharukh.dev/up-time-alert-pro/3.png",
                "https://s3.ap-south-1.amazonaws.com/sharukh.dev/up-time-alert-pro/4.png"
              ],
            });
            break;
          case "mfm":
            setModalStates({
              title: "My Food Menu",
              imgClassNames: "h-full",
              subTitle: "A digital menu for restaurants",
              description:
                "My Food Menu is an innovative hotel management tool for online menus, facilitating easy menu management for hotels and convenient access for customers via QR codes.",
              isWp: false,
              link1:
                "https://menu.myfoodmenu.in/hotel-new-majestic-ajmer",
              link2:
                "https://menu.myfoodmenu.in/hotel-new-majestic-ajmer",
              images: [
                "https://s3.ap-south-1.amazonaws.com/sharukh.dev/my-food-menu/1.png",
                "https://s3.ap-south-1.amazonaws.com/sharukh.dev/my-food-menu/2.png",
                "https://s3.ap-south-1.amazonaws.com/sharukh.dev/my-food-menu/3.png",
                "https://s3.ap-south-1.amazonaws.com/sharukh.dev/my-food-menu/4.png",
                "https://s3.ap-south-1.amazonaws.com/sharukh.dev/my-food-menu/5.png",
                "https://s3.ap-south-1.amazonaws.com/sharukh.dev/my-food-menu/6.png",
                "https://s3.ap-south-1.amazonaws.com/sharukh.dev/my-food-menu/7.png"
              ],
            });
            break;
      }
    }
  }, [showModal]);

  return (
    <>
      <Modal showModal={showModal} onOutsideClick={() => setShowModal(false)}>
        <ModalContent
          onClose={() => {
            setShowModal(false);
          }}
          {...modalStates}
        />
      </Modal>

      <motion.div
        className="relative bg-gray-300 bg-center bg-no-repeat bg-cover h-60"
        style={{
          backgroundImage: `url(${bgUrl})`,
        }}
        onClick={() => setActiveProject(projectKey)}
        onMouseEnter={() => setActiveProject(projectKey)}
        onMouseLeave={() => setActiveProject("")}
        whileHover={{
          rotate: [0, -5, 5, -5, 0],
        }}
        initial="hidden"
        whileInView="visible"
        viewport={{
          once: true,
        }}
        variants={{
          hidden: {
            scale: 0,
          },
          visible: {
            scale: [0, 0.5, 1, 1.2, 1.1, 1],
            transition: {
              delay: index * 0.2,
              duration: 0.5,
              type: "spring",
            },
          },
        }}
      >
        <AnimatePresence exitBeforeEnter initial={false}>
          {activeProject === projectKey && (
            <>
              <motion.div
                className="flex flex-col w-full h-full text-center bg-gray-100 justify-evenly "
                variants={projectVariants}
                initial="hidden"
                animate="visible"
                exit="hidden"
              >
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    setActiveProject("");
                  }}
                  className="absolute top-0 right-0 flex items-center justify-center w-5 h-5 m-2 text-sm text-white bg-gray-800 rounded-full"
                >
                  x
                </button>
                <motion.div
                  className="absolute top-0 left-0 hidden px-2 py-1 mt-2 ml-2 text-xs border rounded-full sm:block transition-css border-secondary"
                  variants={tagVariants}
                >
                  {tag}
                </motion.div>
                <div className="">
                  <motion.h3
                    className="text-xl font-semibold uppercase lg:text-2xl "
                    variants={titleVariants}
                  >
                    {title}
                  </motion.h3>
                  <motion.span
                    className="inline-block text-xs font-medium lg:text-sm text-highlight"
                    variants={techVariants}
                  >
                    {tech.join(" / ")}
                  </motion.span>
                  {keyPoint && (
                    <motion.p
                      className="block font-semibold text-center transform text-primary mt-1.5"
                      variants={keyPointsVariants}
                    >
                      {keyPoint}
                    </motion.p>
                  )}
                </div>
                <motion.div
                  className="flex justify-center"
                  variants={learnMoreVariants}
                >
                  <Button
                    onClick={() => {
                      setShowModal(true);
                    }}
                    className="px-10 py-1 text-sm uppercase border-highlight hover:border-highlight hover:bg-highlight hover:text-white"
                  >
                    Learn More
                  </Button>
                </motion.div>
              </motion.div>
            </>
          )}
        </AnimatePresence>
      </motion.div>
    </>
  );
}

const tagVariants = {
  hidden: {
    y: "-100%",
    opacity: 0,
  },
  visible: {
    y: "0%",
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
};

const projectVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      delay: 0.1,
      when: "beforeChildren",
    },
  },
};

const titleVariants = {
  hidden: {
    x: "-200",
    opacity: 0,
  },
  visible: {
    x: 0,
    opacity: 1,
    transition: {
      type: "spring",
      stiffness: 100,
      damping: 15,
      duration: 0.5,
    },
  },
};

const techVariants = {
  hidden: {
    x: "200",
    opacity: 0,
  },
  visible: {
    x: 0,
    opacity: 1,
    transition: {
      type: "spring",
      stiffness: 100,
      damping: 15,
      duration: 0.5,
      delay: 0.3,
    },
  },
};

const keyPointsVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      delay: 0.5,
    },
  },
};

const learnMoreVariants = {
  hidden: {
    y: "100%",
    opacity: 0,
  },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      type: "spring",
      stiffness: 100,
      damping: 15,
      duration: 0.5,
      delay: 0.8,
    },
  },
};
