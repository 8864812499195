import { motion, MotionStyle } from "framer-motion";
import { SliderProps } from "../../utils/types";

const pageStyle: MotionStyle = {
  width: "100%",
  height: "100%",
  flex: "none",
};

const Slider = ({ x, i, onDragEnd, children }: SliderProps) => (
  <motion.div
    style={{
      ...pageStyle,
      x,
      left: `${i * 100}%`,
      right: `${i * 100}%`,
    }}
    drag="x"
    dragElastic={0.3}
    onDragEnd={onDragEnd}
    className="flex items-center justify-center w-full h-full"
  >
    {children}
  </motion.div>
);

export default Slider;
