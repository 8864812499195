import { useState } from "react";
import { useEffect } from "react";
import Desktop from "./Desktop";
import Mobile from "./Mobile";
import { useDebouncedCallback } from "use-debounce";
import { className } from "../../../utils/className";
import { motion } from "framer-motion";

export default function NavBar() {
  const [isScrolled, setIsScrolled] = useState(false);

  const debounced = useDebouncedCallback(
    // function
    () => {
      const scrolled = window.pageYOffset;

      if (scrolled > 1050) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    },
    // delay in ms
    500
  );

  useEffect(() => {
    window.addEventListener("scroll", debounced);

    return () => {
      window.removeEventListener("scroll", debounced);
    };
  }, [debounced]);

  return (
    <>
      <motion.div
        className={className("md:hidden block", {
          "fixed w-full z-10 top-0": isScrolled,
        })}
      >
        <motion.div
          initial={{
            y: 0,
          }}
          animate={{
            y: isScrolled ? [-100, 0] : 0,
            transition: {
              duration: 1,
            },
          }}
        >
          <Mobile />
        </motion.div>
      </motion.div>
      <div
        className={className("md:block hidden", {
          "fixed w-full z-10 top-0": isScrolled,
        })}
      >
        <motion.div
          initial={{
            y: 0,
          }}
          animate={{
            y: isScrolled ? [-100, 0] : 0,
            transition: {
              duration: 1,
            },
          }}
        >
          <Desktop />
        </motion.div>
      </div>
    </>
  );
}
