import "./App.css";
import About from "./Components/sections/About";
import Blog from "./Components/sections/Blog";
import Contact from "./Components/sections/Contact";
import Footer from "./Components/sections/Footer";
import Hero from "./Components/sections/Hero";
import NavBar from "./Components/sections/Nav";
import Projects from "./Components/sections/Projects";
import { useEffect, useState } from "react";

function App() {
  const [canView, setCanView] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setCanView(true);
    }, 4000);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <>
      {/* <CustomCursor /> */}
      <div id="home">
        <Hero />
      </div>
      {canView ? (
        <>
          <div>
            <NavBar />
          </div>
          <div id="about" className=" dark:bg-bg-secondary">
            <About />
          </div>
          <div className="bg-gray-50 dark:bg-my-bg" id="portfolio">
            <Projects />
          </div>
          <div id="blog" className="dark:bg-bg-secondary">
            <Blog />
          </div>
          <div className="bg-[#252934] dark:bg-my-bg relative" id="contact">
            <Contact />
          </div>
          <div className="bg-[#1b242f]  dark:bg-bg-secondary ">
            <Footer />
          </div>
        </>
      ) : null}
    </>
  );
}

export default App;
