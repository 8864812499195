/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useContext, useState } from "react";

interface Props {
  children: React.ReactNode;
}

const Context = React.createContext({
  activeNav: "home",
  theme: parseInt(localStorage.getItem("theme") || "1"),
});

const ContextUpdater = React.createContext({
  setActiveNav: (activeNav: string) => {},
  setTheme: (t: number) => {},
});

export function useStateContext() {
  return useContext(Context);
}

export function useContextUpdater() {
  return useContext(ContextUpdater);
}

export function ContextProvider({ children }: Props) {
  const [states, setStates] = useState({
    activeNav: "home",
    theme: parseInt(localStorage.getItem("theme") || "1"),
  });

  const setTheme = (t: number) => {
    setStates({
      ...states,
      theme: t,
    });
  };

  const setActiveNav = (activeNav: string) => {
    setStates({
      ...states,
      activeNav,
    });
  };

  return (
    <Context.Provider value={{ ...states }}>
      <ContextUpdater.Provider value={{ setActiveNav, setTheme }}>
        {children}
      </ContextUpdater.Provider>
    </Context.Provider>
  );
}
