import { className } from "../../utils/className";
import { ArrowProps } from "../../utils/types";

const Arrow = ({ left = false, children, onClick }: ArrowProps) => (
  <div
    onClick={onClick}
    className={className(
      "bottom-0 absolute w-12 h-12 bg-[#00000050] cursor-pointer text-white text-xl flex items-center justify-center",
      {
        "left-0": left,
        "right-0": !left,
      }
    )}
  >
    {children}
  </div>
);

export default Arrow;
